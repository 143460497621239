import { useCallback } from "react";
import invariant from "tiny-invariant";
import { v4 as uuid } from "uuid";
import { useCommand } from "@lookiero/messaging-react";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { fillScreen } from "../../../../domain/screen/command/fillScreen";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";
const useFillScreen = ({ customerId, slug, logger }) => {
    const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
    const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
    const fill = useCallback(async ({ answers }) => {
        invariant(customerId, "customerId must be defined in order to fill the screen");
        try {
            // There is no Screen AggRoot on the backend, so provided id does not matter.
            await commandBus(fillScreen({ aggregateId: uuid(), customerId, slug, answers }));
        }
        catch (error) {
            createNotification({
                bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
                level: NotificationLevel.ERROR,
            });
            logger.captureException(error);
        }
    }, [commandBus, createNotification, customerId, logger, slug]);
    return [fill, status];
};
export { useFillScreen };
