import { questionHasChildren, QuestionType, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
const sortQuestionAnswerByDefinition = ({ question, answer }) => {
    if ([
        QuestionType.HOST_DATE,
        QuestionType.HOST_HEIGHT_IMPERIAL,
        QuestionType.HOST_HEIGHT_METRIC,
        QuestionType.HOST_WEIGHT_IMPERIAL,
        QuestionType.HOST_WEIGHT_METRIC,
    ].includes(question.type)) {
        return answer;
    }
    if (!questionHasChildren(question)) {
        return answer;
    }
    let sortedAnswer = [];
    const sortAnswer = (question) => {
        for (let i = 0; i < question.children.length; i++) {
            const childQuestion = question.children[i];
            if (answer.includes(childQuestion.id) && !sortedAnswer.includes(childQuestion.id)) {
                sortedAnswer = [...sortedAnswer, childQuestion.id];
            }
            if (questionHasChildren(childQuestion)) {
                sortAnswer(childQuestion);
            }
        }
    };
    sortAnswer(question);
    return sortedAnswer;
};
const sortQuestionAnswersByDefinition = ({ question, answers }) => {
    const sortedAnswers = {};
    const mapQuestion = (question) => {
        const questionAnswer = answers[question.id];
        if (questionAnswer) {
            sortedAnswers[question.id] = sortQuestionAnswerByDefinition({ question, answer: questionAnswer });
        }
        if (questionHasChildren(question)) {
            question.children.forEach(mapQuestion);
        }
    };
    mapQuestion(question);
    return sortedAnswers;
};
export { sortQuestionAnswersByDefinition };
