import React, { useCallback, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import Animated, { FadeIn, FadeOut, LinearTransition, useAnimatedStyle, withDelay, withTiming, } from "react-native-reanimated";
import { NextBestActionCard } from "../../nextBestActionCard/NextBestActionCard";
import { style } from "./NextBestActionGrid.style";
const NEXT_BEST_ACTION_GRID_MAX_ACTIONS = 4;
const layoutAnimations = {
    entering: FadeIn.delay(600).duration(300),
    exiting: FadeOut.delay(300).duration(300),
    layout: LinearTransition.delay(450).duration(300),
};
// eslint-disable-next-line react/prop-types
const NextBestActionGrid = ({ actions = [], onActionTriggered }) => {
    const gridActions = useMemo(() => actions.slice(0, NEXT_BEST_ACTION_GRID_MAX_ACTIONS), [actions]);
    const initialActions = useRef(actions);
    const renderWithLayoutAnimations = initialActions.current !== actions;
    const [gridHeight, setGridHeight] = useState();
    const handleOnLayout = useCallback(({ nativeEvent: { layout } }) => setGridHeight(layout.height), []);
    const gridAnimatedStyle = useAnimatedStyle(() => gridHeight
        ? {
            height: withDelay(300, withTiming(gridHeight, { duration: 300 })),
        }
        : {}, [gridHeight]);
    return (React.createElement(Animated.View, { style: gridAnimatedStyle },
        React.createElement(View, { style: style.gridContainer, onLayout: handleOnLayout }, gridActions.map((action) => (React.createElement(Animated.View, { key: action.id, ...(renderWithLayoutAnimations ? layoutAnimations : {}), style: style.card },
            React.createElement(NextBestActionCard, { action: action, status: action.status, onActionTriggered: onActionTriggered })))))));
};
export { NextBestActionGrid, NEXT_BEST_ACTION_GRID_MAX_ACTIONS };
