import React, { memo } from "react";
import { Navigate, Outlet, useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import { Kameleoon } from "@lookiero/sty-psp-ab-testing";
import { StaticInfoProvider } from "../hooks/useStaticInfo";
import { App } from "../views/App";
import { Home } from "../views/home/Home";
import { NotFound } from "../views/notFound/NotFound";
import { RouteScreen } from "../views/screen/RouteScreen";
import { Routes } from "./routes";
const Routing = ({ apiUrl, getAuthToken, basePath = "", customer, I18n, kameleoon, layout, locale, androidInAppBrowser, lookAndLike, onBack, onI18nError, useRoutes = reactRouterUseRoutes, }) => {
    return useRoutes([
        {
            path: "",
            element: (React.createElement(StaticInfoProvider, { androidInAppBrowser: androidInAppBrowser, apiUrl: apiUrl, basePath: basePath, customer: customer, getAuthToken: getAuthToken, kameleoon: kameleoon },
                React.createElement(I18n, { loader: React.createElement(Spinner, null), locale: locale, onError: onI18nError },
                    React.createElement(Kameleoon, { loader: React.createElement(Spinner, null), siteCode: kameleoon.siteCode },
                        React.createElement(App, null,
                            React.createElement(Outlet, null)))))),
            children: [
                {
                    path: Routes.HOME,
                    element: (React.createElement(Home, { layout: layout, lookAndLike: lookAndLike, onBack: onBack },
                        React.createElement(Outlet, null))),
                    children: [
                        {
                            path: Routes.SCREEN,
                            element: React.createElement(RouteScreen, null),
                        },
                    ],
                },
                {
                    path: Routes.NOT_FOUND,
                    element: React.createElement(NotFound, { layout: layout }),
                },
                {
                    path: "*",
                    element: React.createElement(Navigate, { to: `${Routes.HOME}`, replace: true }),
                },
            ],
        },
    ]);
};
/**
 * Provided useRoutes is not stable (when integrated with Sentry) as it's rendering a different component tree.
 *
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/reactrouterv6.tsx#L221
 * (SentryRoutes is a new component after each re-render)
 */
const MemoizedRouting = memo(Routing);
export { MemoizedRouting as Routing };
