import React, { Fragment, useCallback, useRef } from "react";
import { Modal } from "@lookiero/sty-psp-ui";
import { useTrackScreenNavigationBack } from "../../../tracking/useTrackScreenNavigationBack";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { Screen } from "./Screen";
const ScreenModal = ({ providers: Providers = Fragment, slug, onBack, onSuccess }) => {
    const modalVisible = Boolean(slug);
    const scrollRef = useRef(null);
    const { customer: { customerId, country, segment }, } = useStaticInfo();
    const trackScreenNavigationBack = useTrackScreenNavigationBack({
        country,
        segment,
        customerId,
    });
    const screenRef = useRef();
    const handleOnScreenLoaded = useCallback(({ screen }) => {
        screenRef.current = screen;
    }, []);
    const handleOnBack = useCallback(() => {
        trackScreenNavigationBack({
            slug: screenRef.current?.metadata.slug,
            group: screenRef.current?.metadata.groupTranslationKey,
        });
        if (onBack) {
            onBack();
        }
    }, [onBack, trackScreenNavigationBack]);
    return (React.createElement(Modal, { portalHostName: "StyleProfile", scrollRef: scrollRef, testID: "screen", visible: modalVisible, scroll: true, showCloseButton: true, onClose: handleOnBack },
        React.createElement(Providers, null,
            React.createElement(Screen, { scrollRef: scrollRef, slug: slug, onScreenLoaded: handleOnScreenLoaded, onSuccess: onSuccess }))));
};
export { ScreenModal };
