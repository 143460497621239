import React from "react";
import { View } from "react-native";
import { Button, BUTTON_VARIANT, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Modal } from "@lookiero/sty-psp-ui";
import { I18nMessages } from "../../../../../../../i18n/i18n";
import { style } from "./ImagePickerModal.style";
const ImagePickerModal = ({ visible, disabled = false, onDismiss, onTakePhoto, onChooseFromLibrary, }) => {
    const titleText = useI18nMessage({ id: I18nMessages.IMAGE_PICKER_MODAL_TITLE });
    const takePhotoButtonText = useI18nMessage({ id: I18nMessages.IMAGE_PICKER_MODAL_TAKE_PHOTO_BUTTON });
    const chooseFromLibraryButtonText = useI18nMessage({
        id: I18nMessages.IMAGE_PICKER_MODAL_CHOOSE_FROM_LIBRARY_BUTTON,
    });
    return (React.createElement(Modal, { portalHostName: "StyleProfile", visible: visible, showCloseButton: true, onClose: onDismiss },
        React.createElement(View, { style: style.modal },
            React.createElement(Text, { level: 1, action: true }, titleText),
            React.createElement(View, { style: style.modalGap },
                React.createElement(Button, { disabled: disabled, level: 3, style: [style.button, style.takePhotoButton], variant: BUTTON_VARIANT.SECONDARY, upperCase: true, wide: true, onPress: onTakePhoto }, takePhotoButtonText),
                React.createElement(Button, { disabled: disabled, level: 3, style: style.button, variant: BUTTON_VARIANT.SECONDARY, upperCase: true, wide: true, onPress: onChooseFromLibrary }, chooseFromLibraryButtonText)))));
};
export { ImagePickerModal };
