import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { useViewStyleProfileNextBestActionDefinitionByCustomerId } from "../../../projection/nextBestActionDefinition/react/useViewStyleProfileNextBestActionDefinitionByCustomerId";
import { useTrackNextBestActionClick } from "../../../tracking/useTrackNextBestActionClick";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { I18nMessages, PREFIX } from "../../i18n/i18n";
import { ScreenModal } from "../screen/ScreenModal";
import { style } from "./NextBestAction.style";
import { NextBestActionCardStatus, } from "./components/nextBestActionCard/NextBestActionCard";
import { NextBestActionCarousel } from "./components/nextBestActionCards/nextBestActionCarousel/NextBestActionCarousel";
import { NextBestActionGrid } from "./components/nextBestActionCards/nextBestActionGrid/NextBestActionGrid";
import { NextBestActionSuccess } from "./components/nextBestActionSuccess/NextBestActionSuccess";
const NextBestAction = ({ screenModalProviders: ScreenModalProviders, onReady, onCompleted, onNavigateToStyleProfile, }) => {
    const { customer: { customerId, country, segment }, } = useStaticInfo();
    const [actions] = useViewStyleProfileNextBestActionDefinitionByCustomerId({ customerId });
    const initialActions = useRef(actions?.actions);
    initialActions.current = initialActions.current ?? actions?.actions;
    const hasActions = Boolean(initialActions.current?.length);
    const finished = initialActions.current !== actions?.actions && actions?.actions.length === 0;
    const onReadyCalled = useRef(false);
    const onCompletedCalled = useRef(false);
    useEffect(() => {
        if (actions && !onReadyCalled.current) {
            onReadyCalled.current = true;
            onReady({ questions: actions.actions.length });
        }
        if (hasActions && actions?.actions.length === 0 && !onCompletedCalled.current) {
            onCompletedCalled.current = true;
            onCompleted();
        }
    }, [hasActions, actions, onCompleted, onReady]);
    const title = useI18nMessage({ id: I18nMessages.NEXT_BEST_ACTION_TITLE, prefix: PREFIX });
    // THIS IS SUPPOSED TO BE INCLUDED IN THE NEAR FUTURE
    // const progress = useI18nMessage({
    //   id: actions?.status.translationKey as string,
    //   values: { progress: String(actions?.status.value) },
    // });
    const [actionStatuses, setActionStatuses] = useState({});
    const actionsWithStatus = useMemo(() => [
        ...(actions?.actions || []).map((action) => ({
            ...action,
            status: actionStatuses[action.id] || NextBestActionCardStatus.IDLE,
        })),
    ], [actionStatuses, actions?.actions]);
    const [screenSlug, setScreenSlug] = useState();
    const closeScreen = useCallback(() => setScreenSlug(""), []);
    const handleScreenFilledSuccess = useCallback(({ answers }) => {
        closeScreen();
        const aswersAreEmpty = Object.values(answers).every((answer) => answer.length === 0);
        if (aswersAreEmpty) {
            return;
        }
        const actionId = actions?.actions.find((action) => action.slug === screenSlug)?.id;
        if (!actionId) {
            return;
        }
        setActionStatuses((actionStatuses) => ({ ...actionStatuses, [actionId]: NextBestActionCardStatus.SUCCESS }));
    }, [actions?.actions, closeScreen, screenSlug]);
    const trackNextBestActionClick = useTrackNextBestActionClick({ country, customerId, segment });
    const nextBestActionClickTracked = useRef(false);
    const handleOnActionTriggered = useCallback(({ action }) => {
        setScreenSlug(action.slug);
        if (nextBestActionClickTracked.current) {
            return;
        }
        trackNextBestActionClick();
        nextBestActionClickTracked.current = true;
    }, [trackNextBestActionClick]);
    const screenSize = useScreenSize();
    const NextBestActionCards = useMemo(() => (screenSize === "L" ? NextBestActionGrid : NextBestActionCarousel), [screenSize]);
    return hasActions && actions ? (React.createElement(View, { style: style.container },
        React.createElement(View, { style: style.titleContainer },
            React.createElement(Text, { level: 2, style: style.title, action: true }, title)),
        finished ? (React.createElement(NextBestActionSuccess, { onNavigateToStyleProfile: onNavigateToStyleProfile })) : (React.createElement(React.Fragment, null,
            React.createElement(NextBestActionCards, { actions: actionsWithStatus, onActionTriggered: handleOnActionTriggered }),
            React.createElement(ScreenModal, { providers: ScreenModalProviders, slug: screenSlug, onBack: closeScreen, onSuccess: handleScreenFilledSuccess }))))) : null;
};
export { NextBestAction };
