import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-native";
import { useLogger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, Notifications, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { I18nMessages, PREFIX } from "../../i18n/i18n";
import { Routes } from "../../routing/routes";
import { ScreenModal } from "./ScreenModal";
const RouteScreen = () => {
    /**
     * In order to solve the "unmount" transition, this view will allways be rendered
     * under the path ":slug?", setting as visible or not the Modal.
     *
     * Mantaining a reference to the previous question will allow us to hide the Modal while
     * rendering the previous (invalidated) screen projection.
     */
    const { slug } = useParams();
    const navigate = useNavigate();
    const { basePath } = useStaticInfo();
    const logger = useLogger();
    const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
    const navigateToHome = useCallback(() => navigate(`${basePath}/${Routes.HOME}`), [basePath, navigate]);
    const handleOnSuccess = useCallback(() => {
        createNotification({
            bodyI18nKey: `${PREFIX}${I18nMessages.SAVE_CONFIRMATION_TOAST}`,
            level: NotificationLevel.SUCCESS,
        });
        navigateToHome();
    }, [createNotification, navigateToHome]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenModal, { slug: slug, onBack: navigateToHome, onSuccess: handleOnSuccess }),
        React.createElement(Notifications, { contextId: MESSAGING_CONTEXT_ID })));
};
export { RouteScreen };
