import React from "react";
import { Spinner } from "@lookiero/aurora";
import { Kameleoon } from "@lookiero/sty-psp-ab-testing";
import { StaticInfoProvider } from "../../hooks/useStaticInfo";
const nextBestActionProviders = ({ I18n, apiUrl, getAuthToken, kameleoon, onI18nError, androidInAppBrowser }) => 
// eslint-disable-next-line react/display-name, react/prop-types
({ customer, locale, children }) => (React.createElement(StaticInfoProvider, { androidInAppBrowser: androidInAppBrowser, apiUrl: apiUrl, basePath: "" // In this context its not required
    , customer: customer, getAuthToken: getAuthToken, kameleoon: kameleoon },
    React.createElement(Kameleoon, { loader: React.createElement(Spinner, null), siteCode: kameleoon.siteCode },
        React.createElement(I18n, { loader: React.createElement(Spinner, null), locale: locale, onError: onI18nError }, children))));
export { nextBestActionProviders };
