import React, { useCallback } from "react";
import { Button } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CommandStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { isChildQuestion, useAnswersForQuestion, useAnswersValidation, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { useFillScreen } from "../../../../../../domain/screen/react/useFillScreen";
import { useTrackFillScreen } from "../../../../../../tracking/useTrackFillScreen";
import { useTrackQuestionError } from "../../../../../../tracking/useTrackQuestionError";
import { useStaticInfo } from "../../../../../hooks/useStaticInfo";
const HostScreenCta = ({ question, onSuccess }) => {
    const { validateHostScreen, validation } = useAnswersValidation();
    const text = useI18nMessage({ id: question.metadata.ctaTranslationKey });
    const { customer: { customerId, country, segment }, } = useStaticInfo();
    const logger = useLogger();
    const trackQuestionError = useTrackQuestionError({
        group: question.metadata.groupTranslationKey,
        country,
        segment,
        slug: question.metadata.slug,
        customerId,
    });
    const [fillScreen, fillScreenStatus] = useFillScreen({
        customerId: customerId,
        slug: question.metadata.slug,
        logger,
    });
    const trackFillQuiz = useTrackFillScreen({
        country,
        segment,
        slug: question.metadata.slug,
        customerId,
    });
    const { answers: questionAnswers } = useAnswersForQuestion({ question });
    const handleOnPress = useCallback(async () => {
        validateHostScreen({ questionId: question.id });
        trackQuestionError({ validation });
        const hasValidationError = validation?.reduce((acc, v) => acc || isChildQuestion({ questionId: v.questionId, question }), false);
        if (hasValidationError) {
            return;
        }
        await fillScreen({ answers: questionAnswers });
        trackFillQuiz({
            group: question.metadata.groupTranslationKey,
            question: question.metadata.slug,
            answers: questionAnswers,
        });
        onSuccess({ answers: questionAnswers });
    }, [
        fillScreen,
        onSuccess,
        question,
        questionAnswers,
        trackFillQuiz,
        trackQuestionError,
        validateHostScreen,
        validation,
    ]);
    return (React.createElement(Button, { busy: fillScreenStatus === CommandStatus.LOADING, testID: "host-screen-cta", onPress: handleOnPress }, text));
};
export { HostScreenCta };
